.contact-form {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}

.form-input,
.form-textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 95%;
}

.form-input:focus,
.form-textarea:focus {
    border-color: #c59a72;
    outline: none;
    color: black;
}

.form-button {
    padding: 10px;
    background-color: #4c0405;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    color: white;
}

.form-button:hover {
    background-color: #c59a72;
}

.form-status {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    color: green;
}