.courseComponent{
    padding: 5px;
}

.couseDetailContainer{
    display: flex;
    padding: 5px;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
}

.CourseList{
    width: 35%;
    padding: 20px;
    /* border-radius: 10px; */
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    /* background-color: #bfbfff; */
    margin-top: 20px;
    text-align: left;
}

.courseListOdd{
    background-color: #4c0405;
    color: white;
}

.courseListEven{
    background-color: #c59a72;
    color: black;
}

.CourseList p{
    font-weight: 500;
}

/* zic zac design */
.benifitsContainer {
    text-align: center;
    padding: 20px;
  }
  
  .benifitsTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Create the zigzag border effect */
  .zigzag-box {
    padding: 20px;
    background-color: #f0f0f0;
    position: relative;
    margin: 20px auto;
    width: 80%;
    font-family: Arial, sans-serif;
  }
  
  /* Zig-zag top border */
  .zigzag-box:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      135deg,
      transparent 75%,
      #f0f0f0 75%,
      #f0f0f0 85%,
      transparent 85%
    );
    background-size: 30px 30px;
  }
  
  /* Zig-zag bottom border */
  .zigzag-box:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      225deg,
      transparent 75%,
      #f0f0f0 75%,
      #f0f0f0 85%,
      transparent 85%
    );
    background-size: 30px 30px;
  }



  /* --------------------------- */
  /* Main container */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    margin: 10px auto;
    /* position: relative; */
  }
  
  /* Ribbon Banner for Free Classes */
  .ribbon {
    width: 100%;
    /* position: absolute; */
    /* top: -20px; */
    /* left: 0; */
    text-align: center;
  }
  
  .ribbon-title {
    background-color: #ff4b2b;
    color: white;
    padding: 5px 10px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
  }
  
  /* Info Box */
  .info-box {
    /* padding: 20px; */
    background-color: #ffffff;
    border: 2px solid #ff4b2b;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    color: #333;
    /* margin-top: 40px; */
    text-align: start;
    font-size: 18px;
    line-height: 30px;
    padding: 10px;
  }
  
  .info-box p {
    margin: 0;
  }
  
  /* Mobile Responsive */
  @media (max-width: 600px) {
    .container {
      width: 90%;
      /* padding: 20px; */
    }
  
    .ribbon-title {
      font-size: 20px;
      padding: 8px 15px;
    }
  
    .info-box {
      font-size: 16px;
    }
  }

@media screen and (max-width: 850px) {
    .CourseList{
        width: 100%;
    }
    .courseComponent{
        padding: 5px;
    }
  }