.contactContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contactDetail,
.contactForm {
    width: 45%;
    height: fit-content;
}

.contactDetail {
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px 0px 20px 0px;
}

.contactDetail p {
    font-weight: 500;
}


@media screen and (max-width: 850px) {
    .contactContainer {
        flex-direction: column-reverse;
    }

    .contactDetail,
    .contactForm {
        width: 90%;
        height: fit-content;
        margin-top: 20px;
    }

    .contactDetail{
        margin-bottom: 20px;
    }
}