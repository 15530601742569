.headerContainer{
    display: flex;
    background-color: #6c3133;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    align-items: center;
}

.newLogo{
    width: 15vw;
    height: 10vh;
    border-radius: 7px;
    background-color: white;
}

.navigateHome{
    padding: 5px;
    border-radius: 10px;
    background-color: #c59a72;
    height: fit-content;
    text-decoration: none;
    color: black;
    border: 2px solid white;
    font-weight: 500;
    width: 10vw;
}

@keyframes blink-color {
    0% {
      color: red;
    }
    50% {
      color: rgb(238, 255, 0);
    }
    100% {
      color: white;
    }
  }

.blinking-text {
    font-size: 24px;
    font-weight: bold;
    animation: blink-color 1.2s infinite;
    text-shadow: 1px 1px 3px white;
    transition: 1s;
  }

@media screen and (max-width:600px) {
    .newLogo{
        width: 25vw;
        height: 7vh;
    }
    .navigateHome{
        width: 200px;
    }
}